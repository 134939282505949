* {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

html {
    background: #F5F5F5 0% 0% no-repeat padding-box;
}

body {
    -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar {
    height: 8px;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: #4597DC;
    border-radius: 20px;
}

::-webkit-scrollbar-track {
    background: #A88A8A;
    border-radius: 20px;
}


a, a:visited, a:link, a:active {
    text-decoration: none;
    outline:0;
}

.fade {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}